import * as React from 'react';
import Timer from './Timer';

type Props = {
  until: Date | number;
  onTimedOut: Function;
  hideTimer?: boolean;
};

const calculateTimeLeft = (until: Date | number) => +until - +new Date();

const Countdown = ({ until, onTimedOut, hideTimer = false }: Props) => {
  const [timeLeft, setTimeLeft] = React.useState(calculateTimeLeft(until));
  React.useEffect(() => {
    const timer = setInterval(() => {
      const newTimeleft = calculateTimeLeft(until);
      if (newTimeleft < 0) {
        clearInterval(timer);
        onTimedOut();
      }
      setTimeLeft(newTimeleft);
    }, 1000);

    return () => {
      clearInterval(timer);
    };
  }, [until]);
  if (hideTimer) {
    return null;
  }
  return <Timer timeLeft={timeLeft} />;
};

export default Countdown;
