import * as React from 'react';

const Timer = ({ timeLeft }: { timeLeft: number }) => {
  const minutes = Math.floor((timeLeft / (1000 * 60)) % 60);
  const seconds = Math.floor((timeLeft / 1000) % 60);
  return timeLeft > 0 ? (
    <>
      {`${minutes.toString().padStart(2, '0')}min ${seconds
        .toString()
        .padStart(2, '0')}s`}
    </>
  ) : (
    <div>Time&apos;s up</div>
  );
};

export default Timer;
