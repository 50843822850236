import React, { useMemo } from 'react';
import clsx from 'clsx';
import { useRouter } from 'next/dist/client/router';
import { useSelector } from 'react-redux';
import { Alert, Box, Button, Stack, Typography } from '@mui/material';
import Link from '../../atoms/Link/Link';
import AppointmentInfoBar from '@/components/organisms/AppointmentInfoBar/AppointmentInfoBar';
import Text from '../../atoms/Text/Text';
import LoginForm from '../../organisms/Forms/LoginForm';
import { useTranslation } from '@/src/i18n';
import { useIsPartners } from '@/src/hooks/useIsPartners';
import CnilCovidDisclaimer from '../../organisms/CnilCovidDisclaimer/CnilCovidDisclaimer';
import { APP_ROUTES } from '@/src/constants';

import HeadingSection from './HeadingSection';
import { analyticsEvent } from '@/src/analytic_tag_manager';
import { isValidTemporaryAppointment } from '@/components/utils/appointment';
import FormWrapper from '@/components/templates/Connect/FormWrapper';

const LoginRegister = () => {
  const router = useRouter();
  const isWhiteLabel = useIsPartners();
  const { t } = useTranslation();
  const temporaryAppointment = useSelector(
    (state: any) => state?.temporaryAppointments?.item,
  );
  const [fromDocavenue, setFromDocavenue] = React.useState(false);

  const nextPage = router.query?.next;
  const isTeleconsultation = router.query?.isTeleconsultation === 'true';
  // In case the user is redirected to the login page when he's taking an appointment,
  // we have to display a specific message to inform him why he arrived here.
  const isTakingAppointment = nextPage
    ?.toString()
    .startsWith(APP_ROUTES.APPOINTMENT_CONFIRMATION);

  const withTempRdv = useMemo(
    () => isValidTemporaryAppointment(temporaryAppointment),
    [temporaryAppointment],
  );

  React.useEffect(() => {
    if (window.sessionStorage.getItem('fromDocavenue')) {
      setFromDocavenue(true);
    }
  }, []);

  const showNotificationBlock =
    isTeleconsultation || fromDocavenue || router?.query?.fromPreferences;

  return (
    <FormWrapper
      className={clsx({
        'flow-rdv': withTempRdv || router?.query?.fromPreferences,
      })}
    >
      {withTempRdv && <AppointmentInfoBar appointment={temporaryAppointment} />}
      <div className="side-panel">
        {nextPage?.includes('waiting-proposals') && (
          <div className="bloc-highlight is-success">
            <p>{t('login-to-confirm-waiting-proposal')}</p>
          </div>
        )}

        {showNotificationBlock && (
          <Box className="bloc-highlight is-success">
            {isTeleconsultation && (
              <Typography>{t('login-to-go-to-tlc')}</Typography>
            )}

            {fromDocavenue && (
              <Stack spacing={2}>
                <Typography>{t('patient-docavenue')}</Typography>
                <Link href={APP_ROUTES.REGISTER} passHref>
                  <Button
                    fullWidth
                    variant="contained"
                    color="secondary"
                    data-cy="btn-register-docavenue-user"
                  >
                    {t('create-account-maiia')}
                  </Button>
                </Link>
              </Stack>
            )}

            {router?.query?.fromPreferences && (
              <Typography>{t('connect_to_add_favorite')}</Typography>
            )}
          </Box>
        )}
        <HeadingSection />
        {isTakingAppointment && (
          // In case the user is redirected to the login page when he's taking an appointment,
          // we have to display a specific message to inform him why he arrived here.
          <Alert sx={{ mb: 6 }} severity="warning">
            <Typography>{t('login_to_finish_appointment')}</Typography>
          </Alert>
        )}
        <div className="form-wrapper">
          <Text component="h1" variant="title" size="large">
            {isWhiteLabel ? t('login-title-white-label') : t('login')}
          </Text>
          <LoginForm />
          <Typography
            mt={3}
            mb={1}
            component="h2"
            variant="body1"
            textAlign="center"
          >
            {t('no-account-yet')}
          </Typography>
          <Link
            href={{
              pathname: APP_ROUTES.REGISTER,
              query: router.query,
            }}
            onClick={() => {
              analyticsEvent({
                category: 'Authentication',
                action: 'GoToCreateAccount',
              });
            }}
            passHref
          >
            <Button
              fullWidth
              variant="contained"
              color="primary"
              data-cy="btn-register"
              aria-label={t('create-account')}
              title={t('create-account')}
            >
              {t('create-account')}
            </Button>
          </Link>
        </div>

        <div className="disclaimers">
          <CnilCovidDisclaimer />
        </div>
      </div>
      <div
        className="illustration"
        style={{
          backgroundImage: 'url("/static/images/pages_illu/login.svg")',
        }}
      />
    </FormWrapper>
  );
};

export default LoginRegister;
