import React from 'react';
import { Form, Formik, Field, FormikHelpers } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { errorActions } from '@docavenue/core';
import * as yup from 'yup';
import { useRouter } from 'next/router';
import { Button, Stack } from '@mui/material';
import { styled, css } from '@mui/material/styles';
import RequestErrorAlert from '@/components/molecules/RequestErrorAlert/RequestErrorAlert';
import Link from '../../atoms/Link/Link';
import Alert from '../../atoms/Alert/Alert';
import { useTranslation } from '@/src/i18n';
import TextFieldFormik from '../../molecules/Input/TextFieldFormik';
import PasswordInput from '../../molecules/Input/PasswordInput';

import { APP_ROUTES } from '@/src/constants';
import useSSOUserData from '@/src/hooks/useSSOUserData';
import useAuthentication from '@/src/hooks/useAuthentication';
import { LoginFormValues } from '@/src/types/api';

const ForgotPasswordButton = styled(Button)(
  ({ theme }) => css`
    justify-content: start;
    padding-left: 0;
    color: ${theme.palette.info.light};
    font-size: ${theme.typography.body1.fontSize};
  `,
);

const validationSchema = yup.object().shape({
  password: yup.string().required('error:registration_form_password_required'),
  email: yup
    .string()
    .email('error:invalide_format_email')
    .required('error:registration_form_username_required'),
});

const LoginForm = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const router = useRouter();
  const error = useSelector((state: any) => state?.authentication?.error);
  const { loginWithCredentials } = useAuthentication();

  // When "formSubmissionDisabled", the form submission is disabled, we targeting "errors" resource and
  // not "authentication" because on "errorActions.removeError" it will clear the error
  // from the global "errors" resource
  const formSubmissionDisabled = useSelector(
    (state: any) =>
      // eslint-disable-next-line camelcase
      state?.errors?.authentication_create?.code === 'USER_TEMPORARILY_BLOCKED',
  );

  const { data: ssoData } = useSSOUserData();

  const initialValues = ssoData?.ssoProfile
    ? { email: ssoData.ssoProfile.email, password: '' }
    : { email: '', password: '' };
  const isUnlocked = router.query.unlocked;

  const handleSubmit = async (
    values: LoginFormValues,
    { setSubmitting }: FormikHelpers<LoginFormValues>,
  ) => {
    try {
      setSubmitting(true);
      await loginWithCredentials(values);
    } catch (err) {
      // Handled with saga errors and in login method
    } finally {
      setSubmitting(false);
    }
  };

  React.useEffect(() => {
    if (error) {
      dispatch(
        errorActions.removeError({
          resource: 'authentication',
          crudActionType: 'create',
        }),
      );
    }
  }, []);

  return (
    <Formik
      validationSchema={validationSchema}
      initialValues={initialValues}
      onSubmit={handleSubmit}
    >
      {({ isSubmitting }) => (
        <Form method="POST" className="active">
          {isUnlocked && !error && (
            <Alert severity="success" dataCy="form-error">
              {t('unlock_account_success')}
            </Alert>
          )}
          {error && error.code !== 'MFA_NEEDED' && (
            <RequestErrorAlert resource="authentication" action="CREATE" />
          )}
          <Stack spacing={2} className="textfield-migration-done">
            <Field
              type="text"
              name="email"
              data-cy="login-mail"
              placeholder={t('mail')}
              required
              component={TextFieldFormik}
              stripSpaces
            />
            <Field
              className="password"
              type="password"
              name="password"
              data-cy="login-password"
              placeholder={t('password')}
              required
              component={PasswordInput}
            />

            <Link href={APP_ROUTES.FORGOT_PASSWORD} passHref>
              <ForgotPasswordButton
                variant="text"
                color="info"
                data-cy="btn-login-forgot-pwd"
              >
                {t('forgotten-password-?')}
              </ForgotPasswordButton>
            </Link>
          </Stack>

          <Button
            fullWidth
            type="submit"
            variant="contained"
            color="secondary"
            data-cy="submit-login"
            disabled={formSubmissionDisabled || isSubmitting}
            title={t('log-in')}
            aria-label={t('log-in')}
          >
            {t('log-in')}
          </Button>
        </Form>
      )}
    </Formik>
  );
};

export default LoginForm;
