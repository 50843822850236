import * as React from 'react';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

import { FieldProps } from 'formik';
import TextFieldFormik from './TextFieldFormik';
import { useTranslation } from '@/src/i18n';

type Props = {
  hideLockIcon?: boolean;
} & FieldProps;

const PasswordInput = ({ hideLockIcon, ...props }: Props) => {
  const { t } = useTranslation();
  const [isShowPassword, setIsShowPassword] = React.useState(false);
  const handleClickShowPassword = React.useCallback(() => {
    setIsShowPassword(oldValue => !oldValue);
  }, []);
  const handleMouseDownPassword = React.useCallback(event => {
    event.preventDefault();
  }, []);

  return (
    <>
      <TextFieldFormik
        {...props}
        type={isShowPassword ? 'text' : 'password'}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                aria-label={
                  isShowPassword
                    ? t('form_account_password_hide')
                    : t('form_account_password_display')
                }
                onClick={handleClickShowPassword}
                onMouseDown={handleMouseDownPassword}
                size="large"
              >
                {isShowPassword ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
    </>
  );
};

export default PasswordInput;
