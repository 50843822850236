import React from 'react';
import { TextField as MuiTextField, TextFieldProps } from '@mui/material';
import { getIn, FieldProps } from 'formik';
import { IMaskInput } from 'react-imask';
import { useTranslation } from '@/src/i18n';

const noop = () => {};

export const fieldToTextField = (
  {
    field,
    form,
    disabled = false,
    toUpperCase = false,
    pasteDisabled,
    stripSpaces,
    ...props
  }: any,
  t: Function,
) => {
  const { name } = field;
  const { touched, errors, isSubmitting } = form;

  const fieldError = getIn(errors, name);
  const showError = getIn(touched, name) && !!fieldError;

  const onChange = event => {
    if (toUpperCase)
      form.setFieldValue(field.name, event.target.value.toUpperCase());
    else if (stripSpaces) {
      form.setFieldValue(field.name, event.target.value.trim());
    } else field.onChange(event);
  };
  const onPaste = pasteDisabled ? e => e.preventDefault() : noop;

  return {
    ...field,
    onChange,
    onPaste,
    ...props,
    error: showError,
    helperText: showError ? t(fieldError) : props.helperText,
    disabled: isSubmitting || disabled,
    FormHelperTextProps: {
      'data-i18n-key': fieldError,
    },
  };
};

const TextField = ({ children, ...props }: FieldProps & TextFieldProps) => {
  const { t } = useTranslation();
  return (
    <MuiTextField
      variant="filled"
      {...fieldToTextField(props, t)}
      inputProps={{
        'data-testid': `${props.field?.name}-input`,
        'aria-label': props.placeholder,
        title: props.placeholder,
        ...props.inputProps,
      }}
    >
      {children}
    </MuiTextField>
  );
};

TextField.displayName = 'FormikMaterialUITextField';

export default TextField;

type TextFieldMaskProps = {
  mask: Object;
  hasNotContainsForReset: RegExp;
  inputProps?: Object;
  InputProps?: Object;
} & FieldProps;

// eslint-disable-next-line react/display-name
const MaskInput = React.forwardRef((props, ref) => (
  // @ts-ignore
  <IMaskInput {...props} inputRef={ref} />
));

// eslint-disable-next-line react/display-name
export const TextFieldMask = ({
  mask,
  inputProps,
  hasNotContainsForReset,
  InputProps,
  ...props
}: TextFieldMaskProps) => {
  const { form, field } = props;

  return (
    <TextField
      InputProps={{ ...InputProps, inputComponent: MaskInput as any }}
      // eslint-disable-next-line react/jsx-no-duplicate-props
      inputProps={{
        overwrite: true,
        onAccept: newValue => {
          form.setFieldValue(field.name, newValue);
        },
        ...mask,
        ...inputProps,
        lazy: !(
          field?.value?.length && hasNotContainsForReset?.test(field?.value)
        ),
      }}
      {...props}
    />
  );
};
