import React from 'react';
import { NextSeo } from 'next-seo';
import LoginRegister from '@/components/templates/Connect/LoginRegister';
import { useTranslation } from '@/src/i18n';

const Login = () => {
  const { t } = useTranslation();
  return (
    <>
      <NextSeo
        title={t('seo-title_login')}
        description={t('seo-description_login')}
      />
      <LoginRegister />
    </>
  );
};

Login.redirectAuthenticatedTo = '/';

export default Login;
